<template>
<div>
<p>404 - Not Found</p>
</div>
</template>
<style lang="less">
p {
    font-size: 50px;
}
</style>
